import { ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Button, Spinner } from '@vkontakte/vkui'
import { useEffect, useState } from 'react';
import bridge from '@vkontakte/vk-bridge'
const tonMnemonic = require("tonweb-mnemonic");
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const PayModal = ({input, balance, address, message, tonweb, setModal}) => {
    const [comission, setCommiession] = useState(0)
    const [amount, setAmmount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [body, setBody] = useState(false)
    const pass = useSelector(state => state.user.pass)

    useEffect(() => {
        const start = async () => {

            let res = await fetch('https://tonapi.io/v1/account/getBulkInfo?addresses=' + address)
            res = await res.json()
            if (res.accounts[0].status != 'active') {
                setBody(<div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
                <p>Неактивный контракт</p>
                <p>Не продолжайте если не уверены что контракт активен</p>
            </div>)
            }

            let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
            let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})

            let mnemonic = data.keys[0].value.split(',')

            if (mnemonic.length != 24) {
                mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, pass)
                mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
                mnemonic = mnemonic.split(',')
            }
    
            const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic);
            const WalletClass = tonweb.wallet.all[v.keys[0].value];
            const wallet = new WalletClass(tonweb.provider, {publicKey: keyPair.publicKey});
            const seqno = await wallet.methods.seqno().call() || 0;
    
            if (address.length != 48) return

            const transfer = wallet.methods.transfer({
                secretKey: keyPair.secretKey,
                toAddress: (new tonweb.utils.Address(address)).toString(true, true, false),
                amount: tonweb.utils.toNano(input.toString()), // 0.01 TON
                seqno: seqno,
                payload: message,
                sendMode: 3,
            });
    
            let fee = await transfer.estimateFee();  
            
            fee = fee.source_fees.in_fwd_fee + fee.source_fees.storage_fee + fee.source_fees.gas_fee + fee.source_fees.fwd_fee
            setCommiession(fee)
            if ((+balance * 1000000000) < (+input * 1000000000) + fee) {
                setAmmount((+balance * 1000000000) - fee - 10000)
            } else {
                setAmmount(+input * 1000000000)
            }
        }

        start()
    }, [])


    const send = async () => {
        let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
        let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
        let mnemonic = data.keys[0].value.split(',')

        if (mnemonic.length != 24) {
            mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, pass)
            mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
            mnemonic = mnemonic.split(',')
        }
        
        const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic);
        const WalletClass = tonweb.wallet.all[v.keys[0].value];
        const wallet = new WalletClass(tonweb.provider, {publicKey: keyPair.publicKey});
        const seqno = await wallet.methods.seqno().call() || 0;

        const transfer = wallet.methods.transfer({
            secretKey: keyPair.secretKey,
            toAddress: (new tonweb.utils.Address(address)).toString(true, true, false),
            amount: tonweb.utils.toNano((amount / 1000000000).toFixed(9)), // 0.01 TON
            seqno: seqno,
            payload: message,
            sendMode: 3,
        });

        try {
            const transferSended = await transfer.send(); 
            const transferQuery = await transfer.getQuery(); 

            setModal(
                <ModalRoot activeModal='info'>
                    <ModalPage id="info" dynamicContentHeight onClose={() => {setModal(null)}} header={
                        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
                            Тон отправлен
                        </ModalPageHeader>
                    }>


                    <div className='pay-modal-body'>
                    <div className='transaction-body-avatar'>
                        <ShoppingCartCheckoutIcon style={{ color: 'var(--green-color)'}}/>
                    </div>
                    <p style={{ textAlign: 'center'}}>
                        Ваша транзакция отправлена в сеть и будет обработана через некоторое время
                    </p>

                    <div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
                    <p>-{(amount / 1000000000).toFixed(9)}</p>
                    <hr></hr>
                    <p>Адрес</p>
                        <p>{address}</p>
                        <hr></hr>
                    <p>Комиссия</p>
                        <p>{tonweb.utils.fromNano(comission.toFixed(0))}</p>
                    </div>

                    <Button 
                        stretched appearance='positive' 
                        size='l' 
                        onClick={() => { setModal(null) }}
                    >ОК</Button>
                    </div>

                    </ModalPage>
                </ModalRoot>
            )
        } catch (e) {
            console.error(e);
            setModal(null)
        }
    }
 
    return <ModalRoot activeModal='info'>
    <ModalPage dynamicContentHeight id="info" onClose={() => {setModal(null)}} header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
            Отправить Ton
        </ModalPageHeader>
    }>

    <div className='pay-modal-body'>
        <div className='pay-modal-body-amount'>
            <p>Количество</p>
            <h1>{tonweb.utils.fromNano(amount.toFixed(0))}</h1>
        </div>

        <div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
            <p>Адрес</p>
            <p>{address}</p>
            <hr></hr>
            <p>Комиссия</p>
            <p>{tonweb.utils.fromNano(comission.toFixed(0))}</p>
        </div>

        {
            message ? 
            <div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
                    <p>Комментарий</p>
                    <p>{message}</p>
            </div> : null
        }

        {body}
        <Button 
        stretched appearance='positive' 
        size='l' 
        loading={amount && !loading ? false : true}
        onClick={() => {
            setLoading(true)
            send()
        }}
        >Отправить</Button>
    </div>
    </ModalPage>
</ModalRoot>
}

export default PayModal