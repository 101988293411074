import { ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Button, Alert } from '@vkontakte/vkui'
import { useEffect, useState } from 'react';
import bridge from '@vkontakte/vk-bridge'

const WalletVersion = ({setModal, setStart}) => {
    const [version, setVersion] = useState('v4R2')
    const wallets = ['simpleR1', 'simpleR2', 'simpleR3', 'v2R1', 'v2R2', 'v3R1', 'v3R2', 'v4R1', 'v4R2']
  
    const save = () => {
      bridge.send('VKWebAppStorageSet', {key: 'wallet', value: version})
      bridge.send('VKWebAppStorageSet', {key: 'address', value: ''})
    }
  
    useEffect(() => {
      const start = async () => {
        let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
        setVersion(v.keys[0].value)
      }
      start()
    }, [])
  
    return <ModalRoot activeModal='mnemonic'>
    <ModalPage id="mnemonic" dynamicContentHeight header={
      <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
        Версия Кошелька!
      </ModalPageHeader>
    }>

  
    <div className="modal-content">
    <p>Выберите версию кошелька! Обратите внимание что для каждой версии свой баланс</p>
    
    <div style={{ display: 'flex', flexWrap: 'wrap'}}>
      {
        wallets.map(x => {
          return <Button key={x} style={{ margin: '3px'}} size="l" appearance={version === x ? "positive" : "accent"} stretched onClick={() => {
            setVersion(x)}}>{x}
          </Button>
          })
        }
      </div>
  
    <Button  appearance='positive' style={{ marginTop: '10px'}} mode='outline' size='l' stretched onClick={() => {
      save()
      setStart(Math.random())
      setModal(null)
    }}>Сохранить</Button>
    </div>
  
    </ModalPage>
  </ModalRoot>
  }

export default WalletVersion