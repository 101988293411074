import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import App from "./App";

import { Provider } from 'react-redux'
import { createStore } from "redux";
import { rootReducer } from "./store/rootReducer";

const store = createStore(rootReducer)

window.process = {}
window.process.TonIoClientKey = 'eyJhbGciOiJFZERTQSIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiWE9QX0VIMFQwQiJdLCJleHAiOjE4MzQwNjg1NDcsImlzcyI6IkB0b25hcGlfYm90IiwianRpIjoiWjNRS1hDRlFVNUJWUzZUUTNRWjcyQVlMIiwic2NvcGUiOiJjbGllbnQiLCJzdWIiOiJ0b25hcGkifQ.Iugdzc_3wMvNmI0SAVDx0qgenZ3Ru2DRkCa4t6OYZEkvM8wL7TC9llUL2HmoYhzuCo_bxwjOJf-IueoHwbEFAg'
// Init VK  Mini App
bridge.send("VKWebAppInit");

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById("root"));
if (process.env.NODE_ENV === "development") {
  import("./eruda").then(({ default: eruda }) => {}); //runtime download
}
