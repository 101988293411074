import React, { useEffect, useState } from 'react';
import { Icon20ChevronRight, Icon28QrCodeOutline } from '@vkontakte/icons';
import { Icon28SettingsOutline } from '@vkontakte/icons';
import { Icon56NftHeptagonDashedOutline } from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge'
import { Panel, PanelHeader, PanelHeaderContent, Avatar, Spinner, UsersStack } from '@vkontakte/vkui';
import './Home.css'
import { IconButton } from '@mui/material';
import { Icon28MoneySendOutline } from '@vkontakte/icons';
import { Icon28MoneyRequestOutline } from '@vkontakte/icons';
import Get from './Get';
import Pay from './Pay';
import Settings from './settings/Settings';
import logo from '../img/ton.png'
import { useSelector } from 'react-redux'
import Jetton from './jettons/Jetton';
import Transactions from './Transactions';

const Home = ({ id, fetchedUser, address, tonweb, setModal, setStart, setPopout, go, online, password, setPassword}) => {
	const user = useSelector(state => state.user.user)
	const [version, setVersion] = useState(<Spinner style={{ width: '10px', height: '10px'}}/>)
	const [jettonsBody, setJettonsBody] = useState(null)
	const [nftStack, setNftStack] = useState(null)

	useEffect(() => {
		const vers = async () => {
			let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
			setVersion(v.keys[0].value)
		}
		vers()
	}, [])

	const start = async () => {
		if (!user?.nft) return
		if (user.nft.nft_items.length > 0) {
			let ids = []

			let i = 0
			user?.nft.nft_items.reverse().map(x => {
				if (i < 2) {
					ids.push(x?.metadata?.image)
				}
				i++
			})

			setNftStack(
				<UsersStack photos={ids} size="s">
			  </UsersStack>
			)
		}
	}

	useEffect(() => {
			start()
	}, [user])

	const oppenQr = () => {
		bridge.send('VKWebAppOpenCodeReader')
		.then((data) => { 
		  if (data.code_data) {
			let _addres = data.code_data.slice(data.code_data.length - 48)
			if (address?.length > 0) {
				setModal(<Pay address={address} setModal={setModal} tonweb={tonweb} to={_addres}/>)
			}
		  }
		})
		.catch((error) => {
		  console.error(error);
		});
	}


	useEffect(() => {
		if (!user?.jettons) return 
		if (user?.jettons.filter(x => !x.active).length > 0) {
			setJettonsBody(
				<div className='history-body'>
				<h1>Токены</h1>
				{user.jettons.map(item => {
					if (!item.active) {
						return <div key={item.jetton_address} className='jettons-body' onClick={() => {
							setModal(<Jetton 
								jetton={item} 
								address={address} 
								setModal={setModal} 
								tonweb={tonweb}
							/>)
						}}>
						<img src={item.metadata.image}/>
	
						<div>
							<h1>{item.metadata.name}</h1>
							<p>{tonweb.utils.fromNano(item.balance)} {item.metadata.symbol}</p>
						</div>

						<Icon20ChevronRight />
					</div>
					}
				})}
			</div>
			)
		} else {
			setJettonsBody(null)
		}

	}, [user])

	return <Panel id={id}>
		<PanelHeader>
		<PanelHeaderContent
            status={address}
            before={<Avatar size={36} src={fetchedUser?.photo_200} />}
          >
            Кошелёк
          </PanelHeaderContent>
		</PanelHeader>
		<div className='card'>
			<div className='card-full' style={{ top: '20px'}}>
				{version || 0}
			</div>

			<div className='card-full' style={{ top: '35px'}}>
				<img src={logo} style={{ marginRight: '3px', width: '12px', justifyContent: 'center'}}/>
				{user?.price?.toFixed(2) || 0} ₽
			</div>

			<div className='card-full'>
				{user?.balance || 0}
			</div>

			{
				online === 'offline' ? 
				<div className='card-online'>
					<Spinner style={{ width: '10px', height: '10px'}}/>
					<p>Обновление</p>
				</div> :
				online === 'online' ?
				<div className='card-online' style={{ color: 'var(--green-color)'}}>
					<div />
					<p>Подключено</p>
				</div> :
				<div className='card-online' style={{ color: 'var(--red-color)'}}>
					<div style={{ background: 'var(--red-color)'}}/>
					<p>Ошибка!</p>
				</div>
			}

			<div className='card-qr'>
				<IconButton onClick={oppenQr}>
					<Icon28QrCodeOutline fill='white'/>
				</IconButton>
			</div>

			<div className='card-qr' style={{ right: '50px'}}>
				<IconButton onClick={() => {setModal(<Settings 
					setPassword={setPassword}
					password={password}
					tonweb={tonweb} 
					setModal={setModal} 
					setStart={setStart}
					address={address} 
					setPopout={setPopout}/>
				)}}>
					<Icon28SettingsOutline fill='white' />
				</IconButton>
			</div>

			
			<h1 style={{ fontSize: '16px', top: 'calc(50% - 30px)'}}>≈ {(+user?.balance * user?.price || 0).toFixed(2)} ₽</h1>
			<h1>{(+user?.balance || 0).toFixed(2)}</h1>
			<p>{address}</p>
		</div>

		<div className='main-pay-buttons'>
			<div className='pay-button' onClick={() => {
				setModal(<Get address={address} setModal={setModal}/>)
			}}>
				<Icon28MoneyRequestOutline />
				<p>Получить</p>
			</div>

			<div className='pay-button' onClick={() => {
				setModal(<Pay address={address} setModal={setModal} tonweb={tonweb}/>)
			}}>
				<Icon28MoneySendOutline/>
				<p>Отправить</p>
			</div>
		</div>

		<div className='main-pay-buttons'>
		<div className='pay-button' style={{ position: 'relative'}} onClick={() => {
			go({ currentTarget: { dataset: {to: 'nft'} } })
		}}>
				<div className='card-full' style={{ fontWeight: 700, fontSize: '14px'}}>
					{user?.nft?.nft_items ? user?.nft?.nft_items?.length : <Spinner style={{ width: '10px', height: '10px'}}/>}
				</div>
				<Icon56NftHeptagonDashedOutline width={28} height={28}/>
				<div className='stack-images'>{nftStack}</div>
				<p>NFT</p>
			</div>
		</div>

		{jettonsBody}

		<div className='history-body'>
			<h1>История</h1>
			<Transactions setModal={setModal} tonweb={tonweb}/>
		</div>
	</Panel>
}


export default Home;
