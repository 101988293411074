
import HistoryTwoToneIcon from '@mui/icons-material/HistoryTwoTone';
import Transaction from './Transaction';
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import NftBody from './nft/NftBody';
import { Spinner } from '@vkontakte/vkui';


const SetNft = ({ x, user, tonweb, setModal }) => {
    const [nft, setNft] = useState(null)
    const [collection, setCollection] = useState(null)

    useEffect(() => {
        fetch('https://tonapi.io/v1/nft/getItem?account=' + x.NftItemTransfer.nft, {
            method: 'GET', 
            headers: new Headers({
                'Authorization': 'Bearer ' + window.process.TonIoClientKey, 
            })
        }).then(async data => {
            let collection = null
            let datax = await data.json()
            if (datax.collection_address) {
                collection = await fetch('https://tonapi.io/v1/nft/getCollection?account=' + datax.collection_address, {
                    method: 'GET', 
                    headers: new Headers({
                        'Authorization': 'Bearer ' + window.process.TonIoClientKey, 
                    })
                })
                collection = await collection.json()
            }

            setNft(datax)
            setCollection(collection)
        })    
    }, [])


    if (!nft) return null

    return <div className='nft-history-item-main' onClick={() => {
        setModal(<NftBody address={user.address} tonweb={tonweb} item={nft} setModal={setModal} send={true}/>)
    }}>
            {
                (new tonweb.utils.Address(x?.NftItemTransfer?.recipient?.address)).toString(true, true, true) === user?.address || null ? 
                    <p style={{ marginBottom: '15px'}}>Получено</p> :
                    <p style={{ marginBottom: '15px'}}>Отправлено</p>
            }

        <div className='nft-history-item'>
            <img src={nft.metadata.image} />
            <div>
                <h1>{nft.metadata.name}</h1>
                <p>{collection ? collection.metadata.name : 'Одиночная NFT'}</p>
            </div>
        </div>
        
        {
            (new tonweb.utils.Address(x?.NftItemTransfer?.recipient?.address)).toString(true, true, true) === user?.address || null ? 
                <h1 className='transaction-history-item-wallet' style={{ marginTop: '15px', width: '80%', marginLeft: '10px'}}>{new tonweb.utils.Address(x?.NftItemTransfer?.sender?.address).toString(true, true, true)}</h1> :
                <h1 className='transaction-history-item-wallet' style={{ marginTop: '15px', width: '80%', marginLeft: '10px'}}>{new tonweb.utils.Address(x?.NftItemTransfer?.recipient?.address).toString(true, true, true)}</h1>
        }
    </div>
    
}


const Transactions = ({ setModal, tonweb }) => {
    const user = useSelector(state => state.user.user)

    if (user?.transactions.length < 1) return <div className='transactions-null'>
        <HistoryTwoToneIcon style={{ fontSize: 60 }}/>
        <h1>Здесь появятся ваши транзакции</h1>
    </div>

    if (!user?.transactions) return <Spinner size='medium' style={{ marginTop: '20px'}}/>
        

    return <div>
        { 
        user?.transactions.map(item => {
        return <div key={item.event_id} className='transaction-history-item-main'>
            {
                item.actions.map((x, index) => {
                    if (x.type === 'Unknown') {
                        return <div key={index} className='transaction-history-item' style={{ margin: 0}}>
                                <h1 style={{ margin: 0, fontSize: '16px'}}>Unknown</h1>       
                                <p style={{ color: 'var(--red-color)'}}>-{tonweb.utils.fromNano(item.fee.total.toString()  || '0')}</p>
                                <div className='transaction-history-item-date'>{(new Date(item.timestamp * 1000)).toLocaleString('ru-RU')}</div>
                        </div>
                    }

                    if (x.TonTransfer) {
                        if (x?.TonTransfer?.recipient?.address) {
                            return <div 
                                style={{ 
                                    margin: 0, 
                                    borderBottom: item.actions[item.actions.indexOf(x) + 1] ? '1px solid var(--separator_common)' : null,
                                    borderRadius: 0
                                }}

                                key={index} 
                                className='transaction-history-item' onClick={() => {
                                setModal(<Transaction 
                                    user={user} 
                                    tonweb={tonweb} 
                                    item={{...x, timestamp: item.timestamp, fee: item.fee.total, lt: item.lt, hash: item.event_id}} 
                                    setModal={setModal} address={user?.address}
                                    />)
                            }}>
                            <div>
                            {
                            (new tonweb.utils.Address(x?.TonTransfer?.recipient?.address)).toString(true, true, true) === user?.address || null ? 
                                <p>Получено</p> :
                                <p>Отправлено</p>
                            }

                            <h1 className='transaction-history-item-wallet'>{
                                (new tonweb.utils.Address(x?.TonTransfer?.recipient?.address)).toString(true, true, true) === user?.address || null ? 
                                (new tonweb.utils.Address(x.TonTransfer?.sender.address)).toString(true, true, true) || null :
                                    x.TonTransfer.amount ?
                                (new tonweb.utils.Address(x.TonTransfer?.recipient.address)).toString(true, true, true) || null
                                : <p>UNKNOWN</p>
                            }</h1>
                            </div>
        
                            {
                            (new tonweb.utils.Address(x.TonTransfer?.recipient.address)).toString(true, true, true) === user?.address || null ? 
                            <p style={{ color: 'var(--green-color)'}}>+{tonweb.utils.fromNano(x.TonTransfer.amount.toString() || '0')}</p> :
                            <p style={{ color: 'var(--red-color)'}}>-{tonweb.utils.fromNano(x.TonTransfer.amount.toString()  || '0')}</p>
                            }
        
                            <div className='transaction-history-item-date'>{(new Date(item.timestamp * 1000)).toLocaleString('ru-RU')}</div>
                            </div>
                        }
                    }

                    if (x.NftItemTransfer) {
                        return <SetNft key={index} x={x} user={user} tonweb={tonweb} setModal={setModal}/>
                    }

                    if (x.JettonTransfer) {
                        return <div key={index}  className='transaction-history-item' style={{ 
                            margin: 0, 
                            borderBottom: item.actions[item.actions.indexOf(x) + 1] ? '1px solid var(--separator_common)' : null,
                            borderRadius: 0
                        }}>
                        <img style={{ 
                            width: '30px', 
                            borderRadius: '50%',
                            position: 'absolute',
                            left: '10px',
                            top: '15px'
                        }} src={x.JettonTransfer.jetton.image} />

                        <div style={{ marginLeft: '40px' }}>
                        {
                            (new tonweb.utils.Address(x?.JettonTransfer?.recipient?.address)).toString(true, true, true) === user?.address || null ? 
                                <p>Получено {x.JettonTransfer.jetton.name}</p> :
                                <p>Отправлено {x.JettonTransfer.jetton.name}</p>
                        }

                        <h1 className='transaction-history-item-wallet'>{
                            (new tonweb.utils.Address(x?.JettonTransfer?.recipient?.address)).toString(true, true, true) === user?.address || null ? 
                            (new tonweb.utils.Address(x.JettonTransfer?.sender.address)).toString(true, true, true) || null :
                                x.JettonTransfer.amount ?
                            (new tonweb.utils.Address(x.JettonTransfer?.recipient.address)).toString(true, true, true) || null
                            : <p>UNKNOWN</p>
                        }</h1>
                        </div>

                        {
                            (new tonweb.utils.Address(x.JettonTransfer?.recipient.address)).toString(true, true, true) === user?.address || null ? 
                            <p style={{ margin: 0, color: 'var(--green-color)'}}>+{tonweb.utils.fromNano(x.JettonTransfer.amount.toString() || '0')}</p> :
                            <p style={{ margin: 0, color: 'var(--red-color)'}}>-{tonweb.utils.fromNano(x.JettonTransfer.amount.toString()  || '0')}</p>
                        }

                        </div>
                    }
                    })
                }
                </div>
            })
        }
    </div>
}

export default Transactions