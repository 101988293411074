import './polyfills.ts';
import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol, Alert } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getTonPrice } from './utils/getTonPrice';
import NewPassword from './panels/settings/NewPassword';
import './App.css'

const darkTheme = createTheme({
	palette: {
	  mode: 'dark',
	},
});

const whiteTheme = createTheme({
	palette: {
	  mode: 'light',
	},
});

const httpApiUrl = 'https://toncenter.com/api/v2/jsonRPC';

  
import Home from './panels/Home';

import TonWeb from 'tonweb'
import * as tonMnemonic from 'tonweb-mnemonic';
import NewWallet from './panels/NewWallet';
import Pay from './panels/Pay';
import Nft from './panels/nft/Nft';
import { useDispatch, useSelector } from 'react-redux';
import PasswordMenu from './panels/settings/PasswordMenu';
import CryptoJS from 'crypto-js';

const App = () => {
	const dispatch = useDispatch()
	const [scheme, setScheme] = useState('bright_light')
	const [modal, setModal] = useState(null)
	const [activePanel, setActivePanel] = useState('home');
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	const [address, setAddress] = useState(null);
	const tonweb = new TonWeb(new TonWeb.HttpProvider(httpApiUrl, {apiKey: '18fd3821a33cebb53026b72ac3cba07ad4227eb6f31015e07426e014535b9f42'}))
	const [start, setStart] = useState(0)
	const [online, setOnline] = useState('offline')
	const [password, setPassword] = useState(null)

	const getBalances = async () => {
		if (!address) return
		try {
			setOnline('offline')
			let nft_ = await fetch('https://tonapi.io/v1/nft/getItemsByOwnerAddress?account=' + address, {
				method: 'GET', 
				headers: new Headers({
					'Authorization': 'Bearer ' + window.process.TonIoClientKey, 
				})
			})
			nft_ = await nft_.json()
	
			let jettons_ = await fetch('https://tonapi.io/v1/jetton/getBalances?account=' + address, {
				method: 'GET', 
				headers: new Headers({
					'Authorization': 'Bearer ' + window.process.TonIoClientKey, 
				})
			}
			)
			jettons_ = await jettons_.json()
			for (let i = 0; i < jettons_.balances.length; i++) {
				let active = await bridge.send('VKWebAppStorageGet', {keys: [jettons_.balances[i].jetton_address.slice(2)]})
				jettons_.balances[i].active = active.keys[0].value
				if (jettons_.balances[i].balance === '0') {
					jettons_.balances[i].active = true
				}
			}
	
			let price = await getTonPrice()
			let data = await fetch('https://tonapi.io/v1/event/getAccountEvents?limit=100&account=' + address, {
				method: 'GET', 
				headers: new Headers({
					'Authorization': 'Bearer ' + window.process.TonIoClientKey, 
				})
			})
	
			let balance = await tonweb.getBalance(address);
			balance = balance / 1000000000
			data = await data.json()
			let transactions = data.events
	
			dispatch({type: 'SET_USER', data: {
				balance,
				transactions,
				address,
				price: price.price.usd * price.price.rates.RUB,
				jettons: jettons_.balances,
				nft: nft_
			}})
			setOnline('online')
		} catch (e) {
			setOnline('error')
		}

	}
	
	useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				setScheme(data.scheme)
			}
		});

		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
			let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
			let address = await bridge.send('VKWebAppStorageGet', {keys: ['address']})

			if (!v.keys[0].value) {
				await bridge.send('VKWebAppStorageSet', {key: 'wallet', value: 'v4R2'})
				v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
			}
			
			let mnemonic = []
			if (!data.keys[0].value) {
				setPopout(null);
				return setActivePanel('new-wallet')
			} else {
				mnemonic = data.keys[0].value.split(',')
			}

			let openAllert = null

			if (mnemonic.length != 24) {	
				try {
					if (password) {
						mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, password)
						mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
						mnemonic = mnemonic.split(',')
					}
					if (mnemonic.length != 24) {
						setPopout(null);
						return setModal(<PasswordMenu setStart={setStart} setModal={setModal} setPassword={setPassword} setPopout={setPopout}/>)
					}
				} catch (e) {
					setPopout(null);
					return setModal(<PasswordMenu setStart={setStart} setModal={setModal} setPassword={setPassword} setPopout={setPopout}/>)
				}

				} else {
					if (document.location.hash.slice(1,2) != 't') {
						openAllert = <Alert
								  actions={[
									{
									  title: 'Отмена',
									  autoClose: true,
									  mode: 'destructive',
									  action: () => setPopout(null)
									},
									{
									  title: 'Установить',
									  autoClose: true,
									  mode: 'default',
									  action: () => {
										setPopout(null)
										setModal(<NewPassword setPassword={setPassword} setModal={setModal} setStart={setStart} password={password}/>)
									  }
									},
								  ]}
								  actionsLayout="horizontal"
								  onClose={setPopout(null)}
								  header="Установите пароль!"
								  text="В данный момент ваши ключи не защищены! Установите пароль чтобы зашифровать ключи!"
								/>
					}
				}
			
			if (!address?.keys[0]?.value) {
				const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic);
				const WalletClass = tonweb.wallet.all[v.keys[0].value];
				const _wallet = new WalletClass(tonweb.provider, {publicKey: keyPair.publicKey});
				let _address = await _wallet.getAddress()

				await bridge.send('VKWebAppStorageSet', {key: 'address', value: _address.toString(true, true, true)})
				address = await bridge.send('VKWebAppStorageGet', {keys: ['address']})
			}


			setAddress(address.keys[0].value)
			setUser(user);

			if (openAllert) {
				setPopout(openAllert)
			} else {
				setPopout(null);
			}

			if (document.location.hash.slice(1,2) === 't') {
				let c = null
				if (document.location.hash.slice(52, 53) === 'c') {
					c = document.location.hash.slice(54)
				}
				setModal(<Pay address={address.keys[0].value} setModal={setModal} tonweb={tonweb} to={document.location.hash.slice(3, 51)} c={c}/>)
				document.location.hash = '#'
			}
		}
		fetchData();
	}, [start]);

	useEffect(() => {
		getBalances()
		let interval = setInterval(() => {
			getBalances()
		}, 5000)
		
		return () => clearInterval(interval)
	}, [address])

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
	};

	return (
		<ConfigProvider scheme={scheme}>
			<AdaptivityProvider>
				<AppRoot>
				<ThemeProvider theme={scheme === 'bright_light' ? whiteTheme : darkTheme }>
					<SplitLayout popout={popout} modal={modal}>
						<SplitCol>
							<View activePanel={activePanel}>
								<Home 
									go={go}
									password={password}
									online={online}
									id='home' 
									fetchedUser={fetchedUser} 
									address={address}
									tonweb={tonweb}
									setModal={setModal}
									setStart={setStart}
									setPopout={setPopout}
									setPassword={setPassword}
								/>
								<NewWallet 
									go={go}
									id='new-wallet' 
									address={address}
									tonweb={tonweb} 
									setStart={setStart} 
									tonMnemonic={tonMnemonic} 
									setModal={setModal}
								/>
								<Nft 
									id='nft'
									go={go} 
									tonweb={tonweb} 
									address={address}
									setModal={setModal}
								/>
							</View>
						</SplitCol>
					</SplitLayout>
				</ThemeProvider>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
