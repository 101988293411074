import { useEffect, useState } from "react"
import { ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Avatar, Input, Button, Spinner } from '@vkontakte/vkui'
import bridge from '@vkontakte/vk-bridge'
import TonWeb from 'tonweb'
const tonMnemonic = require("tonweb-mnemonic");
const { JettonWallet } = TonWeb.token.jetton
import { toFriendlyAddress } from '../../utils/utils'
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const getTransaction = async (jetton, amount, toAddress, message, tonweb, address, pass) => {
    let vers = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
    let v = await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
    let mnemonic = vers.keys[0].value.split(',')

    if (mnemonic.length != 24) {
        mnemonic = CryptoJS.AES.decrypt(vers.keys[0].value, pass)
        mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
        mnemonic = mnemonic.split(',')
    }

    const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic)
    const { publicKey, secretKey } = keyPair
    const WalletClass = tonweb.wallet.all[v.keys[0].value];

    const wallet = new WalletClass(tonweb.provider, { publicKey, wc: 0 })
    const seqno = (await wallet.methods.seqno().call()) || 0

    const jettonWalletAddress = toFriendlyAddress(jetton.wallet_address.address)
    const jettonWallet = new JettonWallet(tonweb.provider, { address: jettonWalletAddress })

    const transferBodyParams = {
        jettonAmount: TonWeb.utils.toNano(String(amount)),
        toAddress: new TonWeb.utils.Address(toAddress),
        forwardAmount: TonWeb.utils.toNano('0.01'),
        forwardPayload: new TextEncoder().encode(message),
        responseAddress: new TonWeb.utils.Address(address),
    }

    const payload = await jettonWallet.createTransferBody(transferBodyParams)

    const tx = {
      secretKey,
      toAddress: jettonWalletAddress,
      amount: TonWeb.utils.toNano('0.05'),
      seqno,
      payload,
      sendMode: 3,
    }
      
    return tx
}

const PayJetton = ({ jetton, comment, toAddress, amount, setModal, tonweb, address}) => {
    const [transaction, setTransaction] = useState(null)
    const [commission, setCommiession] = useState(0)
    const pass = useSelector(state => state.user.pass)

    const send = async () => {
        try {
            let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
            let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
            let mnemonic = data.keys[0].value.split(',')

            if (mnemonic.length != 24) {
                mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, pass)
                mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
                mnemonic = mnemonic.split(',')
            }
    
            const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic);
            const WalletClass = tonweb.wallet.all[v.keys[0].value];
            const wallet = new WalletClass(tonweb.provider, {publicKey: keyPair.publicKey});
            await wallet.methods.transfer(transaction).send()

            return setModal(<ModalRoot activeModal='info'>
            <ModalPage id="info" dynamicContentHeight onClose={() => {setModal(null)}} header={
                <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
                    {jetton.metadata.name} отправлен
                </ModalPageHeader>
            }>
    
    
            <div className='pay-modal-body'>
            <div className='transaction-body-avatar'>
                <img src={jetton.metadata.image}  alt=" "
                style={{ width: '100%', height: 'auto', borderRadius: '50%'}}/>
            </div>
            <p style={{ textAlign: 'center'}}>
                Ваша транзакция отправлена в сеть и будет обработана через некоторое время
            </p>

            <div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
                    <p>Количество</p>
                    <p>{amount}</p>
                    <hr></hr>
                    <p>Адрес</p>
                    <p>{toAddress}</p>
                    <hr></hr>
                    <p>Комиссия</p>
                    <p>{tonweb.utils.fromNano(commission.toFixed(0))}</p>
                    <hr></hr>
            </div>
            {
                comment ?
                <div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
                    <p>Комментарий</p>
                    <p>{comment}</p>
                </div> : null
            }

            <Button 
                stretched appearance='positive' 
                size='l' 
                onClick={() => { setModal(null) }}
            >ОК</Button>
            </div>
    
            </ModalPage>
        </ModalRoot>
        )
        } catch (e) {
            setModal(<ModalRoot activeModal='info'>
                <ModalPage id="info" dynamicContentHeight onClose={() => {setModal(null)}} header={
                    <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
                        Ошибка!
                    </ModalPageHeader>
                }>

            <div className="first-menu" style={{ margin: '0 10px', padding: '30px 30px'}}>
                <Icon56ErrorTriangleOutline width={84} height={84}/>
                <h1 style={{ fontSize: '16px'}}>Похоже произошла ошибка. проверьте хватает ли вам Ton для проведения транзакции!
                    Если ошибка повторяется - сообщите пожалуйста в поддержку!
                </h1>
            </div>

            </ModalPage>
            </ModalRoot>
            )

        }
    }

    useEffect(() => {
        const start = async () => {
            let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
            let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
            let mnemonic = data.keys[0].value.split(',')

            if (mnemonic.length != 24) {
                mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, pass)
                mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
                mnemonic = mnemonic.split(',')
            }

            const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic);
            const WalletClass = tonweb.wallet.all[v.keys[0].value];
            const wallet = new WalletClass(tonweb.provider, {publicKey: keyPair.publicKey});

            let tx = await getTransaction(jetton, amount, toAddress, comment, tonweb, address, pass)
            let fee = await wallet.methods.transfer(tx).estimateFee()
            setCommiession(fee.source_fees.fwd_fee + 
                fee.source_fees.gas_fee + 
                fee.source_fees.in_fwd_fee + 
                fee.source_fees.storage_fee
            )
            
            setTransaction(tx)
        }
        start()
    }, [])

    return <ModalRoot activeModal='info'>
    <ModalPage dynamicContentHeight id="info" onClose={() => {setModal(null)}} header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
            Отправить {jetton.metadata.name}
        </ModalPageHeader>
    }>

        {
            transaction ? 
            <div className='pay-modal-body'>
            <div className='transaction-body-avatar'>
                <img src={jetton.metadata.image}  alt=" "
                style={{ width: '100%', height: 'auto', borderRadius: '50%'}}/>
            </div>

            <div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
                <p>Адрес</p>
                <p>{toAddress}</p>
                <hr></hr>
                <p>Комиссия</p>
                <p>{tonweb.utils.fromNano(commission.toFixed(0))}</p>
            </div>

            {
                comment ?
                <div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
                    <p>Комментарий</p>
                    <p>{comment}</p>
                </div> : null
            }

            <Button
                style={{ marginTop: '10px'}} 
                mode='outline' 
                size='l' 
                stretched
                onClick={send}
            >Подтвердить
            </Button>
            </div>
            : 
            <div className="first-menu" style={{ margin: '0 10px', padding: '30px 30px'}}>
                <Spinner style={{marginTop: '30px', paddingBottom: "30px"}} size='large' />
            </div>

        }

    </ModalPage>
</ModalRoot>
}

const Jetton = ({ jetton, address, setModal, tonweb}) => {
    const [toAddress, setToAddress] = useState('')
    const [amount, setAmmount] = useState('')
    const [comment, setComment] = useState('')
    const [commission, setCommiession] = useState('')


    useEffect(() => {
        if (amount > 0 && amount < jetton.balance / 1000000000 && toAddress.length === 48) {
            try {
                setCommiession(1)
            } catch (e) {
                setCommiession(0)
            }
        } else {
            setCommiession(0)
        }

    }, [toAddress, amount, amount])

    return <ModalRoot activeModal='info'>
    <ModalPage heigh id="info" onClose={() => {setModal(null)}} header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
            Отправить {jetton.metadata.name}
        </ModalPageHeader>
    }> 

    <div className='transaction-body' style={{ paddingBottom: 0}}>
    <div className="transaction-body-avatar">
        <Avatar src={jetton.metadata.image}/>
    </div>  
    <input 
        id='input' 
        type='number' 
        max={jetton.balance} 
        defaultValue={0} 
        className='pay-input' 
        onChange={(value) => {
            setAmmount(+value.target.value)
        }}
    />
    <h1 style={{ fontSize: '18px'}}>{tonweb.utils.fromNano(jetton.balance || '0') + ' ' + jetton.metadata.symbol}</h1>
    </div> 

    <div className='pay-modal-body'>
        <Input 
            id='address' 
            placeholder='Адрес кошелька или домен'
            onChange={(value) => {
                setToAddress(value.target.value)
            }}
        />
        <Input 
            id='message' 
            placeholder='Сообщение' 
            style={{ marginTop: '10px'}}
            onChange={(value) => {
                setComment(value.target.value)
            }}
        />

        <Button 
            style={{ marginTop: '10px'}} 
            mode='primary' 
            disabled={commission ? false : true}
            size='l' stretched
            onClick={() => {
                setModal(<PayJetton jetton={jetton} setModal={setModal} toAddress={toAddress} address={address} comment={comment} tonweb={tonweb} amount={amount}/>)
            }}
        >Продолжить
        </Button>
    </div>


    </ModalPage>
    </ModalRoot>
}

export default Jetton