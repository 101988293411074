import { ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Button, Alert } from '@vkontakte/vkui'
import bridge from '@vkontakte/vk-bridge'
import { Icon28DownloadCloudOutline } from '@vkontakte/icons';
import { Icon36CoinsStacks3Outline } from '@vkontakte/icons';
import { Icon28PaymentCardOutline } from '@vkontakte/icons';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

import WalletVersion from './WalletVersion';
import Tockens from './Tockens';
import NewPassword from './NewPassword'

import { Password } from '@mui/icons-material';

const Settings = ({setModal, setStart, setPopout, address, tonweb, setJettons, password, setPassword}) => {
  const pass = useSelector(state => state.user.pass)

  const MNEMONIC = async () => {
    let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
    let mnemonic = data.keys[0].value.split(',')
    
    if (mnemonic.length != 24) {
      mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, pass)
      mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
      mnemonic = mnemonic.split(',')
    }

    let body = []
    for (let index = 0; index < mnemonic.length; index++) {
        body.push(<div key={mnemonic[index]}><div>{index+1}.</div> {mnemonic[index]}</div>)
    }

    setModal(<ModalRoot activeModal='mnemonic'>
    <ModalPage id="mnemonic" dynamicContentHeight header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
          Сохраните мнемонику!
        </ModalPageHeader>
    }>

    <div className="modal-content">
    <p>Сохраните вашу мнемонику! Она позволит восстановить кошелек в любой момент!</p>
    <div className="mnemonic-items">
        {body}
    </div>

    <Button  appearance='positive' style={{ marginTop: '10px'}} mode='outline' size='l' stretched onClick={() => {
      setModal(null)
    }}>
    ОК
    </Button>
    </div>

    </ModalPage>
</ModalRoot>)
  }
    const DELETE = async () => {
        await bridge.send('VKWebAppStorageSet', {key: 'mnemonic', value: ''})
        await bridge.send('VKWebAppStorageSet', {key: 'address', value: ''})
		    setPassword(null)
        setModal(null)
        setStart(11)
    }

    const _DELETE = () => {
        setPopout(
            <Alert
            actions={[
              {
                title: 'Отмена',
                autoClose: true,
                mode: 'cancel',
                action: () => setPopout(null)
              },
              {
                title: 'Удалить',
                autoClose: true,
                mode: 'destructive',
                action: () => DELETE(),
              },
            ]}
            actionsLayout="horizontal"
            onClose={() => {setPopout(null)}}
            header="Выход"
            text="Вы уверены, что хотите выйти? Перед выходом сохраните резервную копию мнемоники, тогда вы сможете подключиться снова!"
          />
        )
    }

    return <ModalRoot activeModal='info'>
    <ModalPage id="info" dynamicContentHeight onClose={() => {setModal(null)}} header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
          Настройки
      </ModalPageHeader>
    }>

    <div className='pay-modal-body'>

        <Button 
          size='l' 
          stretched 
          appearance='positive' 
          before={<Icon28DownloadCloudOutline />} onClick={MNEMONIC}>
          Cохранить секретные ключи
        </Button>

        <Button 
          appearance='accent' 
          style={{ marginTop: '10px'}} 
          mode='outline' size='l' 
          stretched 
          before={<Icon28PaymentCardOutline />}
          onClick={() => {
            setModal(<WalletVersion setModal={setModal} setStart={setStart}/>)
          }}>Версия кошелька
        </Button>

        <Button 
          mode='outline' size='l' 
          style={{ marginTop: '10px'}} 
          stretched before={<Icon36CoinsStacks3Outline width={28} height={28}/>} 
          onClick={() => {
            setModal(<Tockens
              setModal={setModal} 
              setStart={setStart} 
              address={address}
              setJettons={setJettons}
              tonweb={tonweb}
            />)
          }}>Отображение токенов
        </Button>

        <Button 
          appearance='accent' 
          style={{ marginTop: '10px'}} 
          mode='outline' size='l' 
          stretched 
          before={<Password />}
          onClick={() => {
            setModal(<NewPassword setPassword={setPassword} setModal={setModal} setStart={setStart} password={password}/>)
          }}
          >Изменить пароль
        </Button>

        <Button 
          appearance='negative' 
          style={{ marginTop: '10px'}} 
          mode='outline' 
          size='l' 
          stretched 
          onClick={_DELETE}>
            Выйти
        </Button>
    </div>
    </ModalPage>
    </ModalRoot>
}

export default Settings 