import { Spinner, Footer, Panel, PanelHeader, PanelHeaderBack, Button } from '@vkontakte/vkui'
import { useEffect, useState } from 'react';
import { Icon56NftHeptagonDashedOutline } from '@vkontakte/icons';
import NftBody from './NftBody';
import './Nft.css'

const Nft = ({id, address, setModal, go, tonweb}) => {
    const [body, setBody] = useState(<Spinner style={{ margin: '20px auto'}} size='medium' />)

    useEffect(() => {
        const start = async () => {
            if (!address) return
            let nft = await fetch('https://tonapi.io/v1/nft/getItemsByOwnerAddress?account=' + address, {
                method: 'GET', 
                headers: new Headers({
                    'Authorization': 'Bearer ' + window.process.TonIoClientKey, 
                })
            })

            nft = await nft.json()

            if (nft.nft_items.length > 0) {
                setBody(
                        <div className='nft-collection'>{
                            nft.nft_items.map(item => {
                                return <div key={item.address} className='nft-mini-item' onClick={() => {
                                    if (!item?.metadata?.name) return
                                    setModal(<NftBody address={address} tonweb={tonweb} item={item} setModal={setModal} />)
                                }}>
                                    <img src={item?.metadata?.image}  alt=" "/>
                                    <p>{item?.metadata?.name || 'UNKNOWN'}</p>
                                </div>
                            })
                            }
                        </div>
                    )
            } else {
                setBody(
                    <div className="first-menu" style={{ height: '100%' }}>
                        <Icon56NftHeptagonDashedOutline width={84} height={84}/>
                        <h1>Ваша коллекция NFT пока пуста</h1>
                    </div>
                )
            }
        }
        
        start()
    }, [address])

return <Panel id={id}>
        <PanelHeader left={<PanelHeaderBack data-to='home' onClick={go} />}>
            Коллекция NFT
        </PanelHeader>
        <div className='pay-modal-body'>
            {body}
            <Footer>NFT</Footer>
        </div>
    </Panel>
}

export default Nft