import { ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Button } from '@vkontakte/vkui'
import qr from '@vkontakte/vk-qr';
import { useState } from 'react';
import bridge from '@vkontakte/vk-bridge'
import { Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const Get = ({ address, setModal }) => {
    const [snackbar, setSnackbar] = useState(null)

    return <ModalRoot activeModal='info'>
    <ModalPage id="info" dynamicContentHeight onClose={() => {setModal(null)}} header={
    <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
        Получить Ton
    </ModalPageHeader>
    }>

    <div className='transaction-body'>
        <p style={{ opacity: 0.7}}>Поделитесь данной ссылкой чтобы получить Ton</p>

        <div className='links-qr-code-body'>
            <div className='links-qr-code' dangerouslySetInnerHTML={{
                __html: qr.createQR('https://vk.com/app51549860' + '#t_' + address, {
                    qrSize: 200,
                    isShowLogo: true,
                    isShowBackground: true,
                    backgroundColor: '#ffffff'
                })
            }}></div>

            <p onClick={() => {
                bridge.send("VKWebAppCopyText", {text: address});
                setSnackbar('Адрес скопирован!')
            }}>{address}</p>
        </div>

        <div className='links-qr-code-body-buttons'>
            <Button onClick={() => {
                 bridge.send("VKWebAppCopyText", {text: address});
                 setSnackbar('Адрес скопирован!')
            }} mode='secondary' size='l'>Cкопировать</Button>
            <Button  size='l' onClick={() => {
                bridge.send('VKWebAppShare', {
                    link: 'https://vk.com/app51549860' + '#t_' + address
                })
            }}>Поделиться</Button>
        </div>
        </div>

        <Snackbar
            open={snackbar ? true : false}
            onClose={() => setSnackbar(null)}
            message={snackbar}
            action={
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setSnackbar(null)}
                >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
        />
    </ModalPage>
</ModalRoot>
}

export default Get