import { Panel, PanelHeader, ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Button, ButtonGroup} from "@vkontakte/vkui"
import bridge from '@vkontakte/vk-bridge';
import { Icon56WalletOutline } from '@vkontakte/icons';
import './NewWallet.css'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from "react";


const ImportWallet = ({ body, go, tonMnemonic, setModal, setStart, tonweb }) => {
    const [appearance, setApperece] = useState()
    const [active, setActive] = useState('v4R2')
    const wallets = ['simpleR1', 'simpleR2', 'simpleR3', 'v2R1', 'v2R2', 'v3R1', 'v3R2', 'v4R1', 'v4R2']


    const test = async () => {
        let mnemonic = []
        for (let i = 0; i < 24; i++) {
            mnemonic.push(document.getElementById(`select${i}`).value)
        }

        const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic);
        const WalletClass = tonweb.wallet.all[active];
        const _wallet = new WalletClass(tonweb.provider, {publicKey: keyPair.publicKey});
        let _address = await _wallet.getAddress()

        let res = await fetch('https://tonapi.io/v1/account/getBulkInfo?addresses=' + _address)
        res = await res.json()
        if (res.accounts[0].status != 'active') return setApperece(
        <p style={{ color: 'var(--warning)' }}>
            Попробуйте еще раз! Данный кошелёк не активирвоан
        </p>)

        bridge.send('VKWebAppStorageSet', {key: 'mnemonic', value: mnemonic.toString()})
        bridge.send('VKWebAppStorageSet', {key: 'wallet', value: active})
        bridge.send('VKWebAppStorageSet', {key: 'address', value: _address})

        setStart(10)
        setModal(null)
        go({currentTarget: {dataset: {to: 'home'}}})
    }

 return <ModalRoot activeModal='mnemonic'>
        <ModalPage dynamicContentHeight id="mnemonic" onClose={() => {setModal(null)}} header={
            <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
                Заполните поля!
            </ModalPageHeader>
        }>

            <p style={{textAlign: 'center'}}>Заполните 24 слова которые вы получили при регистриации в сети TON</p>
            <div className="mneminic-autocomplit">{body}</div>

            <div className="modal-content">

            <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                {
                    wallets.map(x => {
                        return <Button key={x} style={{ margin: '3px'}} size="l" appearance={active === x ? "positive" : "accent"} stretched onClick={() => {
                            setActive(x)}}>{x}
                      </Button>
                    })
                }
            </div>

                {appearance}
            <Button onClick={test} style={{ marginBottom: '10px', marginTop: '10px'}} size='l' stretched>Импортировать</Button>
            </div>
    </ModalPage>
    </ModalRoot>
}

const NewWallet = ({ id, go, tonMnemonic, setModal, setStart, tonweb }) => {
    const newWalet = async () => {
            let mnemonic = await tonMnemonic.generateMnemonic();

            let body = []
            for (let index = 0; index < mnemonic.length; index++) {
                body.push(<div key={mnemonic[index]}><div>{index+1}.</div> {mnemonic[index]}</div>)
            }

            setModal(<ModalRoot activeModal='mnemonic'>
                <ModalPage id="mnemonic" dynamicContentHeight header={
                    <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
                        Сохраните мнемонику!
                </ModalPageHeader>
            }>

                <div className="modal-content">
                <p>Перед использованием кошелька сохраните вашу мнемонику! Она позволит восстановить кошелек в любой момент!</p>
                <div className="mnemonic-items">
                    {body}
                </div>
                <Button style={{ marginBottom: '10px'}} size='l' stretched onClick={() => {
                    bridge.send('VKWebAppStorageSet', {key: 'mnemonic', value: mnemonic.toString()})
                    bridge.send('VKWebAppStorageSet', {key: 'wallet', value: 'v4R2'})

                    setStart(10)
                    setModal(null)
                    go({currentTarget: {dataset: {to: 'home'}}})

                }}>Начать работу</Button>
                </div>

                </ModalPage>
            </ModalRoot>)
    }

    const importWallet = async () => {
        let mnemonicList = []
        tonMnemonic.wordlists.EN.map(x => {
            mnemonicList.push({ title: x })
        })

        const defaultProps = {
            options: mnemonicList,
            getOptionLabel: (option) => option.title,
        };

        let body = []
        let i = 0
        mnemonicList.slice(0, 24).map(x => {
            body.push(
            <div key={x.title} style={{ display: 'grid', gridTemplateColumns: '20px 1fr'}}>
            <p>{i+1}</p>
            <Autocomplete
                sx={{ margin: '3px'}}
                {...defaultProps}
                id={`select${i}`}
                selectOnFocus
                renderInput={(params) => (
                <TextField {...params} variant="standard" />
            )}
            />
            </div>)
            i++
        })

        setModal(<ImportWallet body={body} go={go} tonMnemonic={tonMnemonic} setModal={setModal} setStart={setStart} tonweb={tonweb}/>)
    }

    return <Panel id={id}>
        <PanelHeader>Новый Кошелёк</PanelHeader>
        <div className="first-menu">
            <Icon56WalletOutline width={84} height={84}/>
            <h1>Привет! В приложении Ton Wallet вы можете безопасно хранить свои монеты, ваш кошелек находится на вашем устройсте, ключи никуда не передаются</h1>
            
            <Button onClick={newWalet} style={{ marginBottom: '10px'}} size='l' stretched>создать кошелек</Button>
            <Button onClick={importWallet}  style={{ marginBottom: '10px'}} size='l' stretched>импортировать существующий</Button>
        </div>
    </Panel>
}

export default NewWallet