import { ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Button, Alert, Input, Footer } from '@vkontakte/vkui'
import { useEffect, useState } from 'react';
import bridge from '@vkontakte/vk-bridge'
import CryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';

const NewPassword = ({setModal, setStart, password, setPassword}) => {
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    const setPass = async () => {
      let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
      if (!data.keys[0].value) return setError('Ошибка!')

      let mnemonic = data.keys[0].value

      try {
        if (mnemonic.split(',').length != 24) {
          mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, document.getElementById('first-password').value);
          mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
          mnemonic = mnemonic.split(',')
          if (mnemonic.length != 24) return setError('Не верный пароль!')
        }
      } catch (e) {
        return setError('Не верный пароль!')
      }


      if (document.getElementById('new-password').value.length < 6) return setError('Минимально 6 символов!')

      if (document.getElementById('new-password').value != document.getElementById('new-password2').value) return setError('Пароли не совпадают!')

      var encrypted = CryptoJS.AES.encrypt(mnemonic.toString(), document.getElementById('new-password').value).toString();
      
      await bridge.send('VKWebAppStorageSet', {key: 'mnemonic', value: encrypted})

      dispatch({type: 'SET_PASS', data: document.getElementById('new-password').value})
      setPassword(document.getElementById('new-password').value)
      setStart(Math.random())
      setModal(null)
    }

    return <ModalRoot activeModal='mnemonic'>
    <ModalPage id="mnemonic" dynamicContentHeight header={
      <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
        Новый пароль!
      </ModalPageHeader>
    }>
  
    <div className="modal-content">
    <p style={{ color: 'red', margin: 0}}>{error}</p>
    {
        password ? 
        <div  style={{ width: '100%'}}>
            <p style={{ marginBottom: '0', textAlign: 'left', textTransform: 'uppercase', opacity: '0.6'}}>Введите старый пароль</p>
            <Input id='first-password' type='password'/>
        </div>
        : null
    }
    
    <div style={{ marginTop: '15px', width: 'calc(100%)'}} >
        <p style={{ marginBottom: '0', textAlign: 'left', textTransform: 'uppercase', opacity: '0.6'}}>Новый пароль</p>
        <Input id='new-password' type='password'/>
        <p style={{ marginBottom: '0', textAlign: 'left', textTransform: 'uppercase', opacity: '0.6'}}>Повторите пароль</p>
        <Input id='new-password2' type='password'/>
    </div>
  
    <Button  appearance='positive' style={{ marginTop: '10px'}} mode='outline' size='l' stretched onClick={setPass}>Сохранить</Button>
    <Footer>Пароль нигде не хранится, и нужен для шифрования ваших ключей!<br/>
    НЕ ПОТЕРЯЙТЕ ПАРОЛЬ! С помощью него будет зашифрована ваша Мнемоника! Никто больше не сможет получить доступ без пароля!<br/>
    Если вы потеряете пароль, восстановить кошелек получится только с помощью мнемоники!</Footer>

    </div>
  
    </ModalPage>
  </ModalRoot>
}

export default NewPassword