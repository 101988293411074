import { 
    ModalRoot, 
    ModalPage, 
    ModalPageHeader, 
    PanelHeaderClose, 
    Separator, 
    Spacing, 
    Footer,
    Button
} from '@vkontakte/vkui'
import bridge from '@vkontakte/vk-bridge'

import { useEffect, useState } from 'react';
import SendNft from './SendNft';

const NftBody = ({ item, setModal, tonweb, address, send}) => {
    const [collection, setCollection] = useState(null)

    useEffect(() => {
        const start = async () => {
            let collection = await fetch('https://tonapi.io/v1/nft/getCollection?account=' + item.collection_address, {
                method: 'GET', 
                headers: new Headers({
                    'Authorization': 'Bearer ' + window.process.TonIoClientKey, 
                })
            })
            collection = await collection.json()
            setCollection(collection)
        }

        start()
    }, [])

    return <ModalRoot activeModal='info'>
    <ModalPage settlingHeight={100} id="info" onClose={() => {setModal(null)}} header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
            {item.metadata.name}
        </ModalPageHeader>
    }>

        <div className='pay-modal-body' style={{ paddingBottom: 0}}>
            <div style={{ padding: '5px', width: 'calc(100% - 20px)'}} className='nft-mini-item'>
                    <img 
                        onClick={() => {
                            bridge.send('VKWebAppShowImages',{
                                images: [item.metadata.image]
                            })
                        }}
                        style={{ 
                            width: '100%', 
                            height: 'auto', 
                            minHeight: '100px'}} 
                            src={item.metadata.image}  alt=" "
                        />
                    <p style={{ width: 'calc(100% - 5px)' }}>{item.metadata.name}</p>

                    {
                        !send ?
                        <Button
                        style={{ marginTop: '10px'}} 
                        mode='outline' 
                        size='l' 
                        onClick={() => {
                            setModal(<SendNft address={address} tonweb={tonweb} item={item} setModal={setModal}/>)
                        }}
                        stretched >Отправить
                    </Button> : null
                    }
            </div>

            <div className='transaction-body-message'>
                <h1 className='transaction-body-message-tag'>Название</h1>
                <p>{item.metadata.name}</p>

                <Spacing size={16}>
                    <Separator />
                </Spacing>

                {
                    item.metadata.description ? 
                    <div>
                            <h1 className='transaction-body-message-tag'>Описание</h1>
                            <p>{item.metadata.description}</p>
                            <Spacing size={16}>
                                <Separator />
                            </Spacing>
                    </div>
                : null
                }   

                {
                    item.metadata?.attributes?.length > 0 ? 
                        <div>
                            <h1 className='transaction-body-message-tag'>Атрибуты</h1>
                            {
                                item.metadata.attributes.map(x => {
                                    return <p>{x.trait_type} : {x.value}</p>
                                })
                            }
                            <Spacing size={16}>
                                <Separator />
                            </Spacing>
                        </div>
                    : null
                }
                
                {
                    collection ? 
                    <div>
                        <h1 className='transaction-body-message-tag'>Коллекция</h1>
                        <div style={{
                            display: 'grid',
                            alignItems: 'center'
                        }}>
                        <img
                            style={{ 
                                width: 'auto', 
                                height: '70px', 
                                margin: '10px auto',
                                borderRadius: '50%',
                                }} 
                            src={collection.metadata.image}  
                            alt=" "
                        />
                        <h1 
                            style={{ textAlign: 'center' }}
                            className='transaction-body-message-tag'>
                                {collection.metadata.name}
                        </h1>
                        <p style={{textAlign: 'center'}}>{collection.metadata.description}</p>
                        </div>
                    </div>
                    : null
                }

            </div>
        </div>
        <Footer>{item.metadata.name}</Footer>
        </ModalPage>
    </ModalRoot>
}

export default NftBody