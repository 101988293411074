import { ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Button } from '@vkontakte/vkui'
import { useEffect, useState } from 'react';
import bridge from '@vkontakte/vk-bridge'
import { Input } from '@vkontakte/vkui';
const tonMnemonic = require("tonweb-mnemonic");
import PayModal from './PayModal';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const Pay = ({address, setModal, tonweb, to, c}) => {
    let [balance, setBalance] = useState(0)
    let [input, setInput] = useState(0)
    let [comission, setComission] = useState(0)
    const pass = useSelector(state => state.user.pass)

    const getComission = async () => {
        let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
        let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
        let mnemonic = data.keys[0].value.split(',')
        
        if (mnemonic.length != 24) {
            mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, pass)
            mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
            mnemonic = mnemonic.split(',')
        }

        const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic);
        const WalletClass = tonweb.wallet.all[v.keys[0].value];
        const wallet = new WalletClass(tonweb.provider, {publicKey: keyPair.publicKey});
        const seqno = await wallet.methods.seqno().call() || 0;
        if (document.getElementById('address')?.value?.length != 48) return

        const transfer = wallet.methods.transfer({
            secretKey: keyPair.secretKey,
            toAddress: (new tonweb.utils.Address(document.getElementById('address').value)).toString(true, true, false),
            amount: tonweb.utils.toNano(input.toString()), // 0.01 TON
            seqno: seqno,
            payload: document.getElementById('message').value || '',
            sendMode: 3,
        });

        let fee = await transfer.estimateFee();  

        setComission(
            fee.source_fees.in_fwd_fee + 
            fee.source_fees.storage_fee + 
            fee.source_fees.gas_fee + 
            fee.source_fees.fwd_fee
        )
    }

    useEffect(() => {
        const start = async () => {
            setBalance(tonweb.utils.fromNano(await tonweb.getBalance(address)))
        }
        start()

        let interval = setInterval(async () => {
            getComission()
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (input > +balance) {
            document.getElementById('input').value = balance
            setInput(balance)
        }
    }, [input])

    return <ModalRoot activeModal='info'>
    <ModalPage dynamicContentHeight id="info" onClose={() => {setModal(null)}} header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
            Отправить Ton
        </ModalPageHeader>
    
    }>

    <div className='pay-modal-body'>
        <div className='pay-modal-body-in'>
            <input id='input' onChange={(value) => {setInput(value.target.valueAsNumber)}} type='number' max={balance} defaultValue={0} className='pay-input' />
            <p>{(balance)}</p>
        </div>
        <Input disabled={to ? true : false} id='address' placeholder='Адрес кошелька или домен' defaultValue={to ? to : ''}/>
        <Input disabled={c ? true : false} id='message' placeholder='Сообщение' style={{ marginTop: '10px'}} defaultValue={c ? c : ''}/>
        <p>Комиссия {tonweb.utils.fromNano(comission.toString())}</p>
        <Button 
            style={{ marginTop: '10px'}} 
            disabled={comission && input ? false : true}
            mode='primary' 
            size='l' stretched
            onClick={() => {
                setModal(<PayModal 
                    tonweb={tonweb} 
                    input={input}
                    setModal={setModal}
                    balance={balance}
                    address={document.getElementById('address').value}
                    message={document.getElementById('message').value}/>)
            }}
        >Продолжить</Button>
    </div>
    </ModalPage>
</ModalRoot>
}

export default Pay