import { ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Separator, Spacing, Spinner, Footer } from '@vkontakte/vkui'
import './Transaction.css'
import GetAppIcon from '@mui/icons-material/GetApp';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import base64 from 'base-64'
import utf8 from 'utf8'
import { useEffect, useState } from 'react';

const Transaction = ({user, item , setModal, tonweb}) => {
    useEffect(() => {
    }, [])
    return <ModalRoot activeModal='info'>
    <ModalPage settlingHeight={100} id="info" onClose={() => {setModal(null)}} header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
            {(new tonweb.utils.Address(item?.TonTransfer?.recipient?.address)).toString(true, true, true) === user?.address ? 'Получено' : 'Отправлено'}
        </ModalPageHeader>
    }>

        <div className='transaction-body' style={{ paddingBottom: 0}}>
        <h1 className='transaction-time'>{(new Date(item.timestamp * 1000)).toLocaleString('ru-RU')}</h1>
        <div className='transaction-body-avatar'>
            {(new tonweb.utils.Address(item?.TonTransfer?.recipient?.address)).toString(true, true, true) === user?.address ? <GetAppIcon /> : <ShoppingCartCheckoutIcon />}
        </div>

        {
			(new tonweb.utils.Address(item?.TonTransfer?.recipient?.address)).toString(true, true, true) === user?.address ? 
			<h1 style={{ color: 'var(--green-color)'}}>+{tonweb.utils.fromNano(item?.TonTransfer?.amount.toString() || '0')} TON</h1> :
			<h1 style={{ color: 'var(--red-color)'}}>-{tonweb.utils.fromNano(item?.TonTransfer?.amount?.toString() || '0')} TON</h1>
		}

        {
            item.TonTransfer.comment ?
            <div className='transaction-body-message'>
                <h1>Комментарий</h1>
                <p>{item.TonTransfer.comment}</p> 
            </div> 
            : null
        }

        {
            <div className='transaction-body-message'>
                <h1>Адрес кошелька</h1>
                {
                (new tonweb.utils.Address(item?.TonTransfer?.recipient?.address)).toString(true, true, true) === user?.address ? 
			        <p>{(new tonweb.utils.Address(item?.TonTransfer?.sender?.address)).toString(true, true, true) || null}</p> :
			        <p>{(new tonweb.utils.Address(item?.TonTransfer?.recipient?.address)).toString(true, true, true) || null}</p>
                }

                <Spacing size={16}>
                    <Separator />
                </Spacing>

                <h1>ID Транзакции</h1>
			        <p>{item.lt}</p>
                        <Spacing size={16}>
                    <Separator />
                </Spacing>

                <h1>Комиссия</h1>
			        <p>{tonweb.utils.fromNano(item.fee.toString())} TON</p> 
                    <Spacing size={16}>
                        <Separator />
                </Spacing>

                <h1>Хэш</h1>
			        <p>{item.hash}</p> 
                    <Spacing size={16}>
                        <Separator />
                </Spacing>
            </div>
        }
    </div>

    <Footer>lt: {item.lt}</Footer>
    </ModalPage>
</ModalRoot>
}

export default Transaction