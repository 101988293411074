const initialState = {
    user: {},
    pass: null
}

export const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.data
            }

        case 'SET_PASS':
            return {
                ...state,
                pass: action.data
            }

        default: 
            return state.user
    }
}