import { 
    ModalRoot, 
    ModalPage, 
    ModalPageHeader, 
    PanelHeaderClose, 
    Input,
    Button,
    Spinner
} from '@vkontakte/vkui'
import { useEffect, useState } from 'react';
import bridge from '@vkontakte/vk-bridge'
import TonWeb from 'tonweb'
const tonMnemonic = require("tonweb-mnemonic");
const { NftItem } = TonWeb.token.nft
import { Icon56ErrorTriangleOutline } from '@vkontakte/icons';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const getTransaction = async (item, value, tonweb, address, pass) => {
    let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
    let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
    let mnemonic = data.keys[0].value.split(',')

    if (mnemonic.length != 24) {
        mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, pass)
        mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
        mnemonic = mnemonic.split(',')
    }

    const newOwnerAddress = new tonweb.utils.Address(value)
    const nftAddress = new tonweb.utils.Address(item.address).toString(true, true, true)
    const collectionAddress = new tonweb.utils.Address(item.collection_address)
    const nftToTransfer = new NftItem(tonweb.provider, { index: item.index, collectionAddress, address: nftAddress })
    const queryId = +Date.now()
    const responseAddress = new tonweb.utils.Address(address)
    const payload = await nftToTransfer.createTransferBody({ queryId, newOwnerAddress, responseAddress })
    const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic);
    const WalletClass = tonweb.wallet.all[v.keys[0].value];
    const wallet = new WalletClass(tonweb.provider, {publicKey: keyPair.publicKey});
    const seqno = await wallet.methods.seqno().call() || 0;
    const amount = tonweb.utils.toNano('0.05')
    const sendMode = 3
    const toAddress = nftAddress
    const tx = { toAddress, amount, seqno, payload, sendMode, secretKey: keyPair.secretKey }

    return tx
}

const PayNft = ({ value, item, setModal, tonweb, address}) => {
    const [transaction, setTransaction] = useState(null)
    const [commission, setCommiession] = useState(0)
    const pass = useSelector(state => state.user.pass)

    const send = async () => {

        try {
            let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
            let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
            let mnemonic = data.keys[0].value.split(',')

            if (mnemonic.length != 24) {
                mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, pass)
                mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
                mnemonic = mnemonic.split(',')
            }
    
            const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic);
            const WalletClass = tonweb.wallet.all[v.keys[0].value];
            const wallet = new WalletClass(tonweb.provider, {publicKey: keyPair.publicKey});
            await wallet.methods.transfer(transaction).send()

            return setModal(<ModalRoot activeModal='info'>
            <ModalPage id="info" dynamicContentHeight onClose={() => {setModal(null)}} header={
                <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
                    NFT отправлен
                </ModalPageHeader>
            }>
    
    
            <div className='pay-modal-body'>
            <div className='transaction-body-avatar'>
                <img src={item.metadata.image}  alt=" "
                style={{ width: '100%', height: 'auto', borderRadius: '50%'}}/>
            </div>
            <p style={{ textAlign: 'center'}}>
                Ваша транзакция отправлена в сеть и будет обработана через некоторое время
            </p>
    
            <div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
            
            <div className='sed-nft-item'>
                <img src={item.metadata.image}  alt=" "/>
                    <div>
                        <h1>{item.metadata.name}</h1>
                        <p>{item.metadata.description}</p>
                    </div>
            </div>
            </div>

            <div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
                    <p>Адрес</p>
                    <p>{value}</p>
                    <hr></hr>
                    <p>Комиссия</p>
                    <p>{tonweb.utils.fromNano(commission.toFixed(0))}</p>
                </div>

            <Button 
                stretched appearance='positive' 
                size='l' 
                onClick={() => { setModal(null) }}
            >ОК</Button>
            </div>
    
            </ModalPage>
        </ModalRoot>
        )
        } catch (e) {
            setModal(<ModalRoot activeModal='info'>
                <ModalPage id="info" dynamicContentHeight onClose={() => {setModal(null)}} header={
                    <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
                        Ошибка!
                    </ModalPageHeader>
                }>

            <div className="first-menu" style={{ margin: '0 10px', padding: '30px 30px'}}>
                <Icon56ErrorTriangleOutline width={84} height={84}/>
                <h1 style={{ fontSize: '16px'}}>Похоже произошла ошибка. проверьте хватает ли вам Ton для проведения транзакции!
                    Если ошибка повторяется - сообщите пожалуйста в поддержку!
                </h1>
            </div>

            </ModalPage>
            </ModalRoot>
            )

        }
    }

    useEffect(() => {
        const start = async () => {
            let data = await bridge.send('VKWebAppStorageGet', {keys: ['mnemonic']})
            let v =  await bridge.send('VKWebAppStorageGet', {keys: ['wallet']})
            let mnemonic = data.keys[0].value.split(',')

            if (mnemonic.length != 24) {
                mnemonic = CryptoJS.AES.decrypt(data.keys[0].value, pass)
                mnemonic = mnemonic.toString(CryptoJS.enc.Utf8)
                mnemonic = mnemonic.split(',')
            }

            const keyPair = await tonMnemonic.mnemonicToKeyPair(mnemonic);
            const WalletClass = tonweb.wallet.all[v.keys[0].value];
            const wallet = new WalletClass(tonweb.provider, {publicKey: keyPair.publicKey});

            let tx = await getTransaction(item, value, tonweb, address, pass)
            let fee = await wallet.methods.transfer(tx).estimateFee()
            setCommiession(fee.source_fees.fwd_fee + 
                fee.source_fees.gas_fee + 
                fee.source_fees.in_fwd_fee + 
                fee.source_fees.storage_fee
            )
            
            setTransaction(tx)
        }
        start()
    }, [])

    return <ModalRoot activeModal='info'>
    <ModalPage dynamicContentHeight id="info" onClose={() => {setModal(null)}} header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
            Отправить NFT
        </ModalPageHeader>
    }>

        {
            transaction ? 
            <div className='pay-modal-body'>
                <div className='sed-nft-item'>
                <img src={item.metadata.image}  alt=" "/>
                <div>
                    <h1>{item.metadata.name}</h1>
                    <p>{item.metadata.description}</p>
                </div>
                </div>

            <div className='transaction-body-message' style={{ margin: '10px 0', width: 'calc(100% - 20px)'}}>
                <p>Адрес</p>
                <p>{value}</p>
                <hr></hr>
                <p>Комиссия</p>
                <p>{tonweb.utils.fromNano(commission.toFixed(0))}</p>
            </div>

            <Button
                style={{ marginTop: '10px'}} 
                mode='outline' 
                size='l' 
                stretched
                onClick={send}
            >Подтвердить
            </Button>
            </div>
            : 
            <div className="first-menu" style={{ margin: '0 10px', padding: '30px 30px'}}>
                <Spinner style={{marginTop: '30px', paddingBottom: "30px"}} size='large' />
            </div>

        }

    </ModalPage>
</ModalRoot>
}

const SendNft = ({ item, setModal, tonweb, address}) => {
    const [value, setValue] = useState('')

    return <ModalRoot activeModal='info'>
    <ModalPage dynamicContentHeight id="info" onClose={() => {setModal(null)}} header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
            Отправить NFT
        </ModalPageHeader>
    }>

        <div className='pay-modal-body'>
        <div className='sed-nft-item'>
            <img src={item.metadata.image}  alt=" "
            />
                <div>
                    <h1>{item.metadata.name}</h1>
                    <p>{item.metadata.description}</p>
                </div>
            </div>
            <Input 
                id='address' 
                placeholder='Адрес кошелька или домен'
                onChange={(value) => {
                    setValue(value.target.value)
                }}
            />
            <Button
                style={{ marginTop: '10px'}} 
                mode='outline' 
                size='l' 
                disabled={value.length === 48 ? false : true}
                onClick={() => {
                    if (value.length === 48) {
                        setModal(<PayNft item={item} setModal={setModal} address={address} tonweb={tonweb} value={value}/>)
                    }
                }}
                stretched >Продолжить
            </Button>
        </div>
    </ModalPage>
</ModalRoot>
}

export default SendNft