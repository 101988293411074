import { ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Button, Alert, Input, Footer } from '@vkontakte/vkui'
import bridge from '@vkontakte/vk-bridge'
import { useDispatch } from 'react-redux';

const PasswordMenu = ({setPassword, setModal, setStart, setPopout}) => {
  const dispatch = useDispatch()
  
  const setPass = () => {
    let pass = document.getElementById('password').value
    dispatch({type: 'SET_PASS', data: pass})

    setPassword(pass)
    setStart(Math.random())
    setModal(null)
  }

  const DELETE = async () => {
    await bridge.send('VKWebAppStorageSet', {key: 'mnemonic', value: ''})
    await bridge.send('VKWebAppStorageSet', {key: 'address', value: ''})
    setModal(null)
    setStart(Math.random())
}


  const _DELETE = () => {
    setPopout(
        <Alert
        actions={[
          {
            title: 'Отмена',
            autoClose: true,
            mode: 'cancel',
            action: () => setPopout(null)
          },
          {
            title: 'Удалить',
            autoClose: true,
            mode: 'destructive',
            action: () => DELETE(),
          },
        ]}
        actionsLayout="horizontal"
        onClose={() => {setPopout(null)}}
        header="Выход"
        text="Вы уверены, что хотите выйти? Перед выходом сохраните резервную копию мнемоники, тогда вы сможете подключиться снова!"
      />
    )
}

  return <ModalRoot activeModal='mnemonic'>
    <ModalPage id="mnemonic" dynamicContentHeight header={
      <ModalPageHeader right={<PanelHeaderClose onClick={() => {setPass(null)}} />}>
        Введите пароль!
      </ModalPageHeader>
    }>
      <div className="modal-content">
      <div  style={{ width: '100%'}}>
        <p style={{ marginBottom: '0', textAlign: 'left', textTransform: 'uppercase', opacity: '0.6'}}>Введите пароль</p>
        <Input id='password' type='password'/>
        <Button  appearance='positive' style={{ marginTop: '10px'}} mode='outline' size='l' stretched onClick={setPass}>Далее</Button>
        <Button 
          appearance='negative' 
          style={{ marginTop: '10px'}} 
          mode='outline' 
          size='l' 
          stretched 
          onClick={_DELETE}>
            Выйти
        </Button>
      </div>
      <Footer>Без пароля вам недоступны переводы и некоторые функции!</Footer>
      </div>

    </ModalPage>
  </ModalRoot>

}

export default PasswordMenu