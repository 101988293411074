import { ModalRoot, ModalPage, ModalPageHeader, PanelHeaderClose, Checkbox, Spinner, Button, Footer } from '@vkontakte/vkui'
import { useEffect, useState } from 'react';
import bridge from '@vkontakte/vk-bridge'
import { Icon56NftHeptagonDashedOutline } from '@vkontakte/icons';

const Tockens = ({setModal, address, tonweb}) => {
    let [tockens, setTockens] = useState(null)

    useEffect(() => {
        const start = async () => {
            let jettons_ = await fetch('https://tonapi.io/v1/jetton/getBalances?account=' + address)
            jettons_ = await jettons_.json()

            for (let i = 0; i < jettons_.balances.length; i++) {
                let active = await bridge.send('VKWebAppStorageGet', {keys: [jettons_.balances[i].jetton_address.slice(2)]})
                jettons_.balances[i].active = active.keys[0].value
            }

            setTockens(jettons_.balances);
        } 
        start()

        return () => {start()}
    }, [])

    return <ModalRoot activeModal='mnemonic'>
    <ModalPage id="mnemonic" dynamicContentHeight header={
        <ModalPageHeader right={<PanelHeaderClose onClick={() => {setModal(null)}} />}>
            Токены!
        </ModalPageHeader>
    }>
  
        {
            tockens ? 
                tockens.length > 0 ?
                tockens.map( item => {
                    return <div key={item.jetton_address} className='jettons-body'>
                            <img src={item.metadata.image}/>
                            <div>
                                <h1>{item.metadata.name}</h1>
                                <p>{tonweb.utils.fromNano(item.balance)} {item.metadata.symbol}</p>
                            </div>
        
                            <Checkbox defaultChecked={!item.active} onClick={async (event) => {
                                if (event.target.checked) {
                                    bridge.send('VKWebAppStorageSet', {key: item.jetton_address.slice(2), value: ''})
                                } else {
                                    bridge.send('VKWebAppStorageSet', {key: item.jetton_address.slice(2), value: 'true'})
                                }
                            }}></Checkbox>
                    </div>
                }) :
                <div className="first-menu">
                    <Icon56NftHeptagonDashedOutline width={84} height={84}/>
                    <h1>У вас пока нет токенов!</h1>
                </div>
            : <Spinner style={{marginTop: '30px', paddingBottom: "30px"}} size='large'/>
        }

<div className="first-menu" style={{ marginTop: '10px'}}>
<Button 
          appearance='accent'  
          mode='outline' size='l' 
          stretched 
          onClick={() => {
            setModal(null)
          }}>ОК
        </Button>
</div>
<Footer style={{ paddingBottom: '30px'}}>Jettons</Footer>
    </ModalPage>
  </ModalRoot>
}

export default Tockens